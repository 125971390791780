<template>
  <div>
    <div class="uk-grid-medium" uk-grid>
      <div class="uk-width-5-5">

        <div class="d-flex">
          <nav id="breadcrumbs" class="mb-3">
            <ul>
              <li><a href="#"> <i class="uil-home-alt"></i> </a></li>
              <li>{{ $t('company.list.company') }}</li>
              <li>{{ $t('general.edit') }}</li>
            </ul>
          </nav>
        </div>
        <div class="card" v-if="loading">
          <default-loading></default-loading>
        </div>
        <template v-else>
          <div class="card">
            <div v-for="error in errorMessages" v-if="errorMessages" class="uk-alert-danger" uk-alert>
              <a class="uk-alert-close" uk-close></a>
              <p style="color:red;">
                <i class="uil-exclamation-triangle"></i> {{ error }}
              </p>
            </div>
            <div v-if="successMessage" class="uk-alert-success" uk-alert>
              <a class="uk-alert-close" uk-close></a>
              <p style="color:green;">
                <i class="uil-exclamation-triangle"></i> {{ $t('general.update_success') }}
              </p>
            </div>
          </div>
        </template>

        <div class="card">
          <div class="uk-padding-large">
            <h4><i class="uil-store-alt"></i> {{ $t('company.information') }}</h4>
            <div class="form-group row mb-1 p-2">
              <div class="col-md-6 offset-md-3 mb-4">
                <label class="text-center">{{ $t('company.avatar') }}</label>
                <div class="d-flex justify-content-center align-items-center">
                  <img class="logoarea rounded img-thumbnail mb-2" :src="item.logo_link" alt="">
                  <input type="file" id="logo" class="d-none" ref="logo" name="filename" @change="processFile($event)">
                  <button type="button" class="btn btn-sm btn-outline-primary ml-2" @click="inputClick()">
                    {{ $t('general.image_change') }}
                  </button>
                </div>
              </div>
            </div>
            <div class="position-relative uk-column-1-2 uk-column-divider">

              <div class="form-group row">
                <div class="col-md-12">
                  <label class="col-form-label" for="name">{{ $t('company.list.company_name') }}<span
                      class="required">*</span></label>
                  <input type="text" class="form-control mb-0" id="name" name="name" placeholder="" v-model="item.name"
                    required="">
                </div>
              </div>

              <div class="form-group row">
                <div class="col-md-12">
                  <label class="col-form-label" for="end_date">{{ $t('company.list.contract_expired_date') }}<span
                      class="required">*</span></label>
                  <input type="date" class="form-control mb-0" id="end_date" name="end_date" placeholder=""
                    v-model="item.contract.end_date" required="">
                </div>
              </div>

              <div class="form-group row" style="margin-top:5rem;">
                <div class="col-md-12">
                  <label class="col-form-label" for="domain">{{ $t('company.domain_name') }}<span
                      class="required">*</span></label>
                  <input type="text" class="form-control mb-0" id="domain" name="domain" placeholder=""
                    v-model="item.domain" required="">
                </div>
              </div>


              <div class="form-group row domain uk-first-column">
                <div class="col-md-12">
                  <label class="col-form-label">{{ $t('company.list.alias_name') }}<span class="required">*</span></label>
                  <div class="d-flex">
                    <input type="text" id="alias" name="alias" v-model="item.alias" placeholder="Alias"
                      class="form-control mb-0">
                    <span>.softinya.com</span>
                  </div>
                </div>
              </div>

              <div class="form-group row" v-if="isAdmin">
                <div class="col-md-12">
                  <label class="col-form-label mb-0" for="domain">Anzera Eğitim Pini</label>
                  <input type="text" class="form-control" v-model="item.pin" placeholder="" required="">
                </div>
              </div>
            </div>

            <div class="uk-card-footer p-0">
              <div class="float-right">
                <button @click="saveForm()" class="btn btn-primary btn-sm mt-2" type="button"><i class="uil-check"></i> {{
                  $t('general.save') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import module, {
  BASE_URL,
  GET_ITEM,
  ITEM,
  MODULE_NAME,
  ERROR,
  UPDATE_ITEM,
  LOADING
} from "@/core/services/store/company.module";
import store from '@/core/services'
import $ from "jquery";
import DefaultLoading from "@/view/components/loading/DefaultLoading";
import Template from '../Mail/Template.vue';

export default {
  name: "CompaniesEdit",
  components: { DefaultLoading },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule)
      }
    }
    registerStoreModule(MODULE_NAME, module)
  },
  data() {
    return {
      imageInput: null,
      successMessage: false,
      errorMessages: [],
    }
  },
  computed: {
    isAdmin() {
      let user = JSON.parse(this.secureLocalStorage("user"));
      let isAdmin = false;
      if (user && user.roles && user.roles.length) {
        isAdmin = !!user.roles.find(item => item.id == 1)
      }
      return isAdmin;
    },
    item: {
      get() {
        return store.getters[MODULE_NAME + '/' + ITEM]
      },
      set(value) {
      }
    },
    errors: {
      get() {
        return store.getters[MODULE_NAME + '/' + ERROR]
      },
      set(value) {
      }
    },
    loading() {
      return store.getters[MODULE_NAME + '/' + LOADING]
    },
  },
  methods: {
    saveForm() {
      let item = this.item;

      this.errorMessages = [];
      if (item.name.length === 0) {
        this.errorMessages.push(this.$t('company.required.name'));
        $("html, body").animate({ scrollTop: 0 }, "slow");
        return false
      }
      if (item.domain.length === 0) {
        this.errorMessages.push(this.$t('company.required.domain'));
        $("html, body").animate({ scrollTop: 0 }, "slow");
        return false
      }
      if (item.alias.length === 0) {
        this.errorMessages.push(this.$t('company.required.alias'));
        $("html, body").animate({ scrollTop: 0 }, "slow");
        return false
      }
      if (item.contract.end_date.length === 0) {
        this.errorMessages.push(this.$t('company.required.end_date'));
        $("html, body").animate({ scrollTop: 0 }, "slow");
        return false
      }

      let formData = new FormData();
      formData.append('avatar', this.$refs.logo.files[0]);
      formData.append('name', item.name);
      formData.append('domain', item.domain);
      formData.append('alias', item.alias);
      formData.append('end_date', item.contract.end_date);
      formData.append('company', item.id);
      formData.append('pin', item.pin);

      store.dispatch(MODULE_NAME + '/' + UPDATE_ITEM, {
        url: BASE_URL + '/' + this.$route.params.id,
        contents: formData,
        returnType: "stateless",
        acceptPromise: true,
      }).then(() => {
        if (!this.error) {
          //$("html, body").animate({ scrollTop: 0 }, "slow")
          //this.successMessage = true;
          this.$router.push({ name: 'company.companies.list', params: { 'succesMessage': this.$t('general.update_success') } })

        } else this.errorMessages.push(this.error)
      })
    },
    processFile(event) {
      this.imageInput = event.target.files[0];
      let blob = URL.createObjectURL(this.imageInput);
      let imageHolder = $('.logoarea');
      imageHolder.attr('src', blob);
    },
    inputClick() {
      $('#logo').click();
    }
  },
  mounted() {
    store.dispatch(MODULE_NAME + '/' + GET_ITEM, {
      url: BASE_URL + '/' + this.$route.params.id,
    })

  }
};
</script>

<style scoped>
.uk-alert {
  margin: 0;
}
</style>
